.label {
  padding: 1px 4px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  background: white !important;
  margin-bottom: 60px !important;
  box-shadow: 0 0 1.5px gray;
  border-radius: 5px;
}

